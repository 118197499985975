<template>
  <div>
    <div>
      <van-nav-bar
        title="天气预报"
        left-arrow
        :fixed="true"
        @click-left="Close"
      />
    </div>
    <div v-if="weatherValue != null">
      <!--显示天气预报-->
      <div class="main">
        <div class="left">
          <span class="word2">{{
            this.weatherValue.realtime.temperature
          }}</span>
        </div>
        <div class="middle">
          <div><span class="word3">℃</span></div>
          <div>
            <span class="info1">{{ this.weatherValue.city }}</span
            ><span class="info2">{{ this.weatherValue.realtime.info }}</span>
          </div>
        </div>
        <div class="right">
          <img
            class="pic1"
            referrerpolicy="no-referrer"
            :src="imgUrl"
            style="width: 64px; height: 64px"
          />
        </div>
      </div>
      <!--显示天气预报-->
      <div class="main1">
        <div class="info-img">
          <van-col span="4">
            <img
              class="img1"
              src="./img/shidu.png"
              style="width: 30px; height: 30px"
          /></van-col>
          <van-col span="4" style="font-size: 20px">湿度</van-col>
          <van-col span="4" offset="12" style="font-size: 16px"
            >{{ this.weatherValue.realtime.humidity }}%</van-col
          >
        </div>
        <div class="info-img">
          <van-col span="4"
            ><img
              class="img1"
              src="./img/fengxiang.png"
              style="width: 30px; height: 30px"
          /></van-col>
          <van-col span="4" style="font-size: 20px">风向</van-col>
          <van-col span="4" offset="12" style="font-size: 16px">{{
            this.weatherValue.realtime.direct
          }}</van-col>
        </div>
        <div class="info-img">
          <van-col span="4"
            ><img
              class="img1"
              src="./img/fengli.png"
              style="width: 30px; height: 30px"
          /></van-col>
          <van-col span="4" style="font-size: 20px">风力</van-col>
          <van-col span="4" offset="12" style="font-size: 16px">{{
            this.weatherValue.realtime.power
          }}</van-col>
        </div>
      </div>
      <!--显示天气预报-->
      <div class="main1">
        <div class="info-img">
          <van-col span="8" style="margin-left: 10px">空气质量指数</van-col>
          <van-col span="2" offset="20" style="font-size: 20px">{{
            this.weatherValue.realtime.aqi
          }}</van-col>
        </div>
        <div>
          <div>
            <van-progress
              :pivot-text="this.weatherValue.realtime.aqi"
              :percentage="this.weatherValue.realtime.aqi"
              stroke-width="2"
            />
          </div>
          <div style="margin-top: 5px">
            <van-col span="4" class="word10">优</van-col>
            <van-col span="4" offset="16" class="word10">危险</van-col>
          </div>
        </div>
      </div>
      <!--显示天气预报-->
      <div class="main3">
        <div>
          <span style="font-size: 20px; margin-left: 10px">近5天天气情况</span>
        </div>
        <div
          class="info-img"
          v-for="(item, index) in this.weatherValue.future"
          :key="index"
        >
          <van-cell>
            <van-col span="6"
              ><span style="display: block">{{ futureInfo[index] }}</span
              ><span
                style="
                  font-size: 12px;
                  color: rgba(153, 153, 153, 1);
                  font-family: PingFangSC-Regular;
                  line-height: 17px;
                "
                >{{ item.date.substring(5, 10) }}</span
              ></van-col
            >
            <van-col span="4" offset="1" class="word10">{{
              item.temperature
            }}</van-col>
            <van-col span="8" offset="1" class="word10">{{
              item.weather
            }}</van-col>
            <van-col span="4">
              <img
                class="icon4"
                referrerpolicy="no-referrer"
                :src="imgInfo[index]"
                style="width: 30px; height: 30px"
              />
            </van-col>
          </van-cell>
        </div>
      </div>
    </div>
    <!--显示天气预报-->
    <!--显示天气预报-->
    <!--显示天气预报-->
    <!--显示天气预报-->
  </div>
</template>
<script>
//import https from '@/router/https' weatherforecast
import { getObj } from "@/api/weatherforecast/weatherforecast"
import { Toast } from "vant"
export default {
  data() {
    return {
      imgUrl: "",
      weatherValue: null,
      city: "合肥",
      futureInfo: [],
      imgInfo: [],
    }
  },
  created() {},
  mounted() {
    if (this.$route.query.city != null) {
      this.city = this.$route.query.city
      if (this.city.indexOf("市") > -1) {
        this.city = this.city.substring(0, this.city.length - 1)
      }
    }
    this.getObj(this.city)
  },
  methods: {
    // 返回原生
    Close() {
      let u = navigator.userAgent
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up")
      }
      if (isAndroid) {
        window.android.close()
      }
    },
    getObj(city) {
      if (city.indexOf("市") > -1) {
        city = city.substring(0, city.length - 1)
      }
      if (city.indexOf("地区") > -1) {
        city = city.substring(0, city.length - 2)
      }
      let params = { city: city }
      getObj(params)
        .then((data) => {
          if (data.data.code === 0) {
            if (data.data.data.result != null && data.data.data != null) {
              this.weatherValue = data.data.data.result
              this.getImgUrl()
              this.getInfo()
            } else {
              //Toast(data.data.data.reason)
              this.$dialog
                .confirm({
                  title: data.data.data.reason,
                  message: "是否显示北京市的天气?",
                })
                .then(() => {
                  this.getObj("北京市")
                })
                .catch(() => {
                  // on cancel
                })
            }
          } else {
            Toast("网络异常")
          }
          //alert(JSON.stringify(this.weatherValue))
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getWeekDay(val) {
      let date = new Date(val)
      var week
      if (date.getDay() == 0) week = "周日"
      if (date.getDay() == 1) week = "周一"
      if (date.getDay() == 2) week = "周二"
      if (date.getDay() == 3) week = "周三"
      if (date.getDay() == 4) week = "周四"
      if (date.getDay() == 5) week = "周五"
      if (date.getDay() == 6) week = "周六"
      return week
    },
    getInfo() {
      if (this.weatherValue.future.length > 0) {
        for (var m = 0; m < this.weatherValue.future.length; m++) {
          this.futureInfo[m] = this.getWeekDay(
            this.weatherValue.future[m].date
          )
          this.imgInfo[m] = this.setImgInfo(
            this.weatherValue.future[m].weather
          )
          //alert(this.weatherValue.future[m].weather)
        }
      }
    },
    getImgUrl() {
      switch (this.weatherValue.realtime.info) {
        case "雷阵雨":
          this.imgUrl = "img/tianqi/tq_24leizhenyu@3x.png"
          break
        case "晴":
          this.imgUrl = "img/tianqi/tq_02qing@3x.png"
          break
        case "多云":
          this.imgUrl = "img/tianqi/tq_04duoyun@3x.png"
          break
        case "阴":
          this.imgUrl = "img/tianqi/tq_06yin@3x.png"
          break
        case "阵雨":
          this.imgUrl = "img/tianqi/tq_08zhenyu@3x.png"
          break
        case "阵雪":
          this.imgUrl = "img/tianqi/tq_10zhenxue@3x.png"
          break
        case "小雨":
          this.imgUrl = "img/tianqi/tq_12xiaoyu@3x.png"
          break
        case "中雨":
          this.imgUrl = "img/tianqi/tq_14zhongyu@3x.png"
          break
        case "大雨":
          this.imgUrl = "img/tianqi/tq_16dayu@3x.png"
          break
        case "暴雨":
          this.imgUrl = "img/tianqi/tq_18baoyu@3x.png"
          break
        case "大暴雨":
          this.imgUrl = "img/tianqi/tq_20dabaoyu@3x.png"
          break
        case "特大暴雨":
          this.imgUrl = "img/tianqi/tq_22tedabaoyu@3x.png"
          break
        case "雷阵雨并伴有冰雹":
          this.imgUrl = "img/tianqi/tq_26leizhenyubingbanyoubingbao@3x"
          break

        case "雨夹雪":
          this.imgUrl = "img/tianqi/tq_28yujiaxue@3x.png"
          break
        case "小雪":
          this.imgUrl = "img/tianqi/tq_30xiaoxue@3x.png"
          break
        case "中雪":
          this.imgUrl = "img/tianqi/tq_32zhongxue@3x.png"
          break
        case "大雪":
          this.imgUrl = "img/tianqi/tq_34daxue@3x.png"
          break
        case "暴雪":
          this.imgUrl = "img/tianqi/tq_36baoxue@3x.png"
          break
        case "雾":
          this.imgUrl = "img/tianqi/tq_38wu@3x.png"
          break
        case "霾":
          this.imgUrl = "img/tianqi/tq_40mai@3x.png"
          break
        case "冻雨":
          this.imgUrl = "img/tianqi/tq_42dongyu@3x.png"
          break
        case "扬沙":
          this.imgUrl = "img/tianqi/tq_44yangsha@3x.png"
          break
        case "沙尘暴":
          this.imgUrl = "img/tianqi/tq_46shachenbao@3x.png"
          break
        case "强沙尘暴":
          this.imgUrl = "img/tianqi/tq_48qiangshachenbao@3x.png"
          break
        case "浮尘":
          this.imgUrl = "img/tianqi/tq_50fuchen@3x.png"
          break
        case "小沙尘暴":
          this.imgUrl = "img/tianqi/tq_46shachenbao@3x.png"
          break
        default:
          this.imgUrl = "img/tianqi/tq_04duoyun@3x.png"
          break
      }
    },
    setImgInfo(val) {
      if (val == "雷阵雨" || val.indexOf("转雷阵雨") > -1) {
        return "img/tianqi/tq_24leizhenyu@2x.png"
      } else if (val == "晴" || val.indexOf("转晴") > -1) {
        return "img/tianqi/tq_02qing@2x.png"
      } else if (val == "多云" || val.indexOf("转多云") > -1) {
        return "img/tianqi/tq_04duoyun@2x.png"
      } else if (val == "阴" || val.indexOf("转阴") > -1) {
        return "img/tianqi/tq_06yin@2x.png"
      } else if (val == "阵雨" || val.indexOf("转阵雨") > -1) {
        return "img/tianqi/tq_08zhenyu@2x.png"
      } else if (val == "阵雪") {
        return "img/tianqi/tq_10zhenxue@2x.png"
      } else if (val == "小雨" || val.indexOf("转小雨") > -1) {
        return "img/tianqi/tq_12xiaoyu@2x.png"
      } else if (val == "中雨" || val.indexOf("转中雨") > -1) {
        return "img/tianqi/tq_14zhongyu@2x.png"
      } else if (val == "大雨" || val.indexOf("转大雨") > -1) {
        return "img/tianqi/tq_16dayu@2x.png"
      } else if (val == "暴雨" || val.indexOf("转暴雨") > -1) {
        return "img/tianqi/tq_18baoyu@2x.png"
      } else if (val == "大暴雨" || val.indexOf("转大暴雨") > -1) {
        return "img/tianqi/tq_20dabaoyu@2x.png"
      } else if (val == "特大暴雨") {
        return "img/tianqi/tq_22tedabaoyu@2x.png"
      } else if (val == "雷阵雨并伴有冰雹") {
        return "img/tianqi/tq_26leizhenyubingbanyoubingbao@2x.png"
      } else if (val == "雨夹雪") {
        return "img/tianqi/tq_28yujiaxue@2x.png"
      } else if (val == "小雪" || val.indexOf("转小雪") > -1) {
        return "img/tianqi/tq_30xiaoxue@2x.png"
      } else if (val == "中雪" || val.indexOf("转中雪") > -1) {
        return "img/tianqi/tq_32zhongxue@2x.png"
      } else if (val == "大雪" || val.indexOf("转大雪") > -1) {
        return "img/tianqi/tq_34daxue@2x.png"
      } else if (val == "暴雪") {
        return "img/tianqi/tq_36baoxue@2x.png"
      } else if (val == "雾") {
        return "img/tianqi/tq_38wu@2x.png"
      } else if (val == "霾") {
        return "img/tianqi/tq_40mai@2x.png"
      } else if (val == "冻雨") {
        return "img/tianqi/tq_42dongyu@2x.png"
      } else if (val == "扬沙") {
        return "img/tianqi/tq_44yangsha@2x.png"
      } else if (val == "沙尘暴") {
        return "img/tianqi/tq_46shachenbao@2x.png"
      } else if (val == "强沙尘暴") {
        return "img/tianqi/tq_48qiangshachenbao@2x.png"
      } else if (val == "浮尘") {
        return "img/tianqi/tq_50fuchen@2x.png"
      } else if (val == "小沙尘暴") {
        return "img/tianqi/tq_46shachenbao@2x.png"
      } else {
        return "img/tianqi/tq_06yin@2x.png"
      }
    },
  },
}
</script>
<style lang="less" scoped>
.main {
  margin-top: 50px;
  margin-left: 2%;
  margin-right: 2%;
  overflow: hidden;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  align-self: center;
  justify-content: center;
  width: 96%;
}
.left {
  width: 25%;
  height: 90px;
  float: left;
}
.right {
  width: 25%;
  height: 90px;
  float: right;
}
.middle {
  width: 50%;
  height: 90px;
  float: left;
}
.word2 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 61px;
  font-family: PingFangSC-Regular;
  line-height: 86px;
  text-align: left;
  margin-left: 10px;
}
.word3 {
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 32px;
  font-family: PingFangSC-Regular;
  line-height: 45px;
  text-align: left;
}
.info2 {
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  font-family: PingFangSC-Regular;
  line-height: 20px;
  text-align: left;
  margin-left: 10px;
}
.main1 {
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 10px;
  overflow: hidden;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  align-self: center;
  justify-content: center;
  width: 96%;
}
.main3 {
  margin-left: 2%;
  margin-right: 2%;
  margin-top: 10px;
  overflow: hidden;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  align-self: center;
  justify-content: center;
  width: 96%;
  margin-bottom: 30px;
  padding-bottom: 30px;
}
.info-img {
  border-bottom: 1px solid rgb(214, 212, 212);
  height: 60px;
  margin-top: 10px;
}
.word10 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
  font-family: PingFangSC-Regular;
  //line-height: 17px;
  text-align: center;
}
.wrap4 {
  height: 57px;
}
.img1 {
  margin-left: 10px;
  width: 30px;
  height: 30px;
}
.van-cell {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  /* line-height: 24px; */
  background-color: #fff;
}
</style>
